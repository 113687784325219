import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@material-ui/core";
import { InputText } from "primereact/inputtext";
import { CommonConfig } from "CommonConfig";
import APIConstant from "utils/constant";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Autocomplete } from "@mui/material";
import api from "utils/api";
import { Button } from "primereact/button";
import cogoToast from "cogo-toast";
import SimpleBackdrop from "utils/general";

const initialValueState = {
  value: "",
  error: false,
  errorText: "",
};

const ProcessMaster = (props) => {
  const [userId, setUserId] = useState("");
  const [userPageAccess, setUserPageAccess] = useState([]);
  const [addProcessDialog, setAddProcessDialog] = useState(false);
  const [editProcessDialog, setEditProcessDialog] = useState(false);
  const [deleteProcessDialog, setDeleteProcessDialog] = useState(false);
  const [addIOProcessDialog, setAddIOProcessDialog] = useState(false);
  const [process, setProcess] = useState({
    name: "",
    error: false,
    errorText: "",
  });
  const [item, setItem] = useState(initialValueState);
  const [unit, setUnit] = useState(initialValueState);
  const [qty, setQty] = useState(initialValueState);

  const [itemList, setItemList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [inputList, setInputList] = useState([]);
  const [outputList, setOutputList] = useState([]);
  const [type, setType] = useState("");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [processId, setProcessId] = useState("");
  const [processList, setProcessList] = useState([]);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState(
    CommonConfig.dataTableConfig.rowsPerPageOptions
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    CommonConfig.dataTableConfig.rowsPerPage
  );
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    let data = localStorage.getItem("Infodata");
    let token = localStorage.getItem("Token");
    let userPageAccess = localStorage.getItem("PageAccess");
    let lockstatus = localStorage.getItem("LockStatus");
    // if (lockstatus == 0) {
    if (token !== null) {
      setUserId(data);
      setUserPageAccess(userPageAccess);
      getProcessList(data);
      getItemList(data);
      getUnitList(data);
    } else {
      props.history.push("/login");
    }
    // } else {
    //     this.props.history.push('/auth/lockscreen')
    // }
  }, []);

  useEffect(() => {
    if (!addProcessDialog) {
      setTimeout(() => {
        setReadOnly(false);
      }, [100]);
    }
  }, [addProcessDialog]);

  const getProcessList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetProcessMaster, params)
      .then((response) => {
        if (response.Success === 1) {
          setProcessList(response.Data);
        }
      })
      .catch((err) => {});
  };

  const getItemList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetItemMasterList, params)
      .then((response) => {
        if (response.Success === 1) {
          let filterArry = response.Data.filter((x) => x.UserId != "Admin").map(
            (data) => ({
              ...data,
              label: data.chapter_name,
              value: data.value,
            })
          );
          setItemList(filterArry);
        }
      })
      .catch((err) => {});
  };

  const getUnitList = async (UserId) => {
    let params = {
      UserId,
    };
    api
      .post(APIConstant.path.GetUnitMaster, params)
      .then((response) => {
        if (response.Success === 1) {
          let filterArry = response.Data;
          setUnitList(filterArry);
        }
      })
      .catch((err) => {});
  };

  const getProcessMasterById = async (ProcessId) => {
    setLoading(true);
    let params = {
      ProcessId,
    };
    api
      .post(APIConstant.path.GetProcessMasterById, params)
      .then((response) => {
        if (response.Success === 1) {
          setLoading(false);
          setProcessId(response.Data.ProcessId);
          setProcess({
            name: response.Data.ProcessName,
            error: false,
            errorText: "",
          });
          setInputList(response.Data.InputList);
          setOutputList(response.Data.OutputList);
          setQty({
            value: response.Data.Quantity,
            error: false,
            errorText: "",
          });
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const deleteProcessMaster = () => {
    setLoading(true);
    let params = {
      ProcessId: processId,
      UserId: userId,
    };
    api.post(APIConstant.path.DeleteProcessMaster, params).then((res) => {
      if (res.Success == 1) {
        setDeleteProcessDialog(false);
        setProcessId("");
        setLoading(false);
        getProcessList(userId);
        cogoToast.success(res.Message);
      } else {
        this.setState({ loading: false });
        cogoToast.error(res.Message);
      }
    });
  };

  const handleClose = (type) => {
    if (type === "AddMaster") {
      setAddProcessDialog(false);
      setProcess({
        name: "",
        error: false,
        errorText: "",
      });
      setProcessId("");
      setInputList([]);
      setOutputList([]);

      // setReadOnly(false);
    } else if (type === "EditMasterType") {
      //   this.setState({ EditMasterTypeModal: false });
    } else if (type === "DeleteMasterType") {
      setDeleteProcessDialog(false);
      setProcessId("");
    } else if (type === "AddIOProcessMaster") {
      setAddIOProcessDialog(false);
      setType("");
      setItem(initialValueState);
      setUnit(initialValueState);
      setReadOnly(false);
      setQty(initialValueState);
    }
  };

  const handleChange = (e, type) => {
    if (type === "processName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setProcess({
          name: e.target.value,
          error: true,
          errorText: "Please enter process name",
        });
      } else {
        setProcess({
          name: e.target.value,
          error: false,
          errorText: "",
        });
      }
    } else if (type === "item") {
      if (CommonConfig.isObjectEmpty(e.value)) {
        setItem({
          value: "",
          error: true,
          errorText: "Please enter item",
        });
      } else {
        let unitId = itemList.find((item) => item.value === e.value)?.unitId || "";
        setItem({
          value: e.value,
          error: false,
          errorText: "",
        });
        setUnit({
          value: unitId,
          error: false,
          errorText: "",
        })
      }
    } else if (type === "unit") {
      if (CommonConfig.isObjectEmpty(e.value)) {
        setUnit({
          value: "",
          error: true,
          errorText: "Please enter unit",
        });
      } else {
        setUnit({
          value: e.value,
          error: false,
          errorText: "",
        });
      }
    } else if (type === "qty") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setQty({
          value: e.target.value,
          error: true,
          errorText: "Please enter quantity",
        });
      } else if (/^\d*$/i.test(e.target.value)) {
        setQty({
          value: e.target.value,
          error: false,
          errorText: "",
        });
      }
    }
  };

  const handleOpen = (item, type) => {
    if (type === "AddMaster") {
      setAddProcessDialog(true);
    } else if (type === "EditMasterType" || type === "ViewMasterType") {
      getProcessMasterById(item._id);
      setAddProcessDialog(true);
      setProcessId(item._id);
      if (type === "ViewMasterType") {
        setReadOnly(true);
      }
    } else if (type === "DeleteMasterType") {
      setDeleteProcessDialog(true);
      setProcessId(item._id);
    } else if (type === "AddIOProcess") {
      setType(item);
      setAddIOProcessDialog(true);
    }
  };

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  const addIOList = () => {
    if (validateIO()) {
      let ioObj = {
        Item: itemList.find((data) => data.value === item.value)?.label,
        ItemId: item.value,
        Unit: unitList.find((data) => data.value === unit.value)?.label,
        UnitId: unit.value,
        Quantity: qty.value,
      };
      if (type === "Input") {
        setInputList([...inputList, ...[ioObj]]);
      }
      if (type === "Output") {
        setOutputList([...outputList, ...[ioObj]]);
      }
      handleClose("AddIOProcessMaster");
    }
  };

  const validateIO = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(item.value)) {
      setItem({
        value: "",
        error: true,
        errorText: "Item is required.",
      });
      formIsValid = false;
    } else {
      setItem((prevState) => ({
        ...prevState,
        error: false,
        errorText: "",
      }));
    }

    if (CommonConfig.isEmpty(unit.value)) {
      setUnit({
        value: "",
        error: true,
        errorText: "Unit is required.",
      });
      formIsValid = false;
    } else {
      setUnit((prevState) => ({
        ...prevState,
        error: false,
        errorText: "",
      }));
    }

    if (CommonConfig.isEmpty(qty.value)) {
      setQty({
        value: "",
        error: true,
        errorText: "Quantity is required.",
      });
      formIsValid = false;
    } else {
      setQty((prevState) => ({
        ...prevState,
        error: false,
        errorText: "",
      }));
    }

    return formIsValid;
  };

  const AddMaster = () => {
    if (validation()) {
      //   this.setState({ loading: true });
      setLoading(true);
      let params = {
        ProcessId: processId,
        ProcessName: process.name,
        UserId: userId,
        InputList: inputList.map((data) => ({
          Item: data.ItemId,
          Unit: data.UnitId,
          Quantity: data.Quantity,
        })),
        OutputList: outputList.map((data) => ({
          Item: data.ItemId,
          Unit: data.UnitId,
          Quantity: data.Quantity,
        })),
      };
      api.post(APIConstant.path.AddUpdateProcessMaster, params).then((res) => {
        if (res.Success == 1) {
          setLoading(false);
          handleClose("AddMaster");
          getProcessList(userId);
          cogoToast.success(res.Message);
        } else {
          setLoading(false);
          cogoToast.error(res.Message);
        }
      });
    }
  };

  const validation = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(process.name)) {
      setProcess({
        name: "",
        error: true,
        errorText: "Process name is required.",
      });
      formIsValid = false;
    } else if (inputList.length <= 0 || outputList.length <= 0) {
      formIsValid = false;
      cogoToast.error("Items Required");
    }

    return formIsValid;
  };

  const actionTemplate = (rowData, props) => {
    return (
      <div class="bill-action icon-only-btn" style={{ display: "flex" }}>
        {userPageAccess.includes("View Process") ? (
          <div onClick={(e) => handleOpen(rowData, "ViewMasterType")}>
            {" "}
            <i class="icon-view"></i>{" "}
          </div>
        ) : null}
        {userPageAccess.includes("Edit Process") ? (
          <div onClick={(e) => handleOpen(rowData, "EditMasterType")}>
            {" "}
            <i class="icon-editImage"></i>{" "}
          </div>
        ) : null}
        {userPageAccess.includes("Delete Process") ? (
          <div onClick={(e) => handleOpen(rowData, "DeleteMasterType")}>
            {" "}
            <i class="icon-delete"></i>{" "}
          </div>
        ) : null}
      </div>
    );
  };

  const actionBodyTemplate = (rowData, props, value) => {
    return (
      <div class="bill-action icon-only-btn" style={{ display: "flex" }}>
        <div onClick={(e) => handleDelete(rowData, props, value)}>
          {" "}
          <i class="icon-delete"></i>{" "}
        </div>
      </div>
    );
  };

  const handleDelete = (rowData, props, value) => {
    console.log(rowData, value);
    if (value === "input") {
      let newList = [...inputList];
      newList.splice(props.rowIndex, 1);
      setInputList(newList);
    } else if (value === "output") {
      let newList = [...outputList];
      newList.splice(props.rowIndex, 1);
      setOutputList(newList);
    }
  };

  const actionBodyData = (rowData, props, type, field) => {
    if (field === "Unit") {
      return (
        <Autocomplete
          id={type}
          name={type}
          value={
            rowData.UnitId && unitList.length
              ? unitList.filter((y) => y.value == rowData.UnitId)[0]
              : ""
          }
          options={unitList}
          getOptionLabel={(option) => (option.label ? option.label : option)}
          disabled={readOnly}
          onChange={(e, value) => {
            if (type === "input") {
              let tempData = [...inputList];
              tempData[props.rowIndex].UnitId = value?.value;
              setInputList(tempData);
            } else {
              let tempData = [...outputList];
              tempData[props.rowIndex].UnitId = value?.value;
              setOutputList(tempData);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Unit"
              disabled={readOnly}
            />
          )}
        />
      );
    } else
      return (
        <TextField
          autoFocus
          margin="dense"
          name={type}
          type="text"
          value={rowData.Quantity}
          onChange={(e) => {
            if (/^\d*$/i.test(e.target.value) || e.target.value === "") {
              if (type === "input") {
                let tempData = [...inputList];
                tempData[props.rowIndex].Quantity = e.target.value;
                setInputList(tempData);
              } else {
                let tempData = [...outputList];
                tempData[props.rowIndex].Quantity = e.target.value;
                setOutputList(tempData);
              }
            }
          }}
          inputProps={{ maxLength: 10 }}
          fullWidth
          disabled={readOnly}
        />
      );
  };

  return (
    <div className="wayment-owner-dashboard e-weighment-dashoard">
      <div className="heading-area p-d-flex p-ai-center p-jc-between">
        <div className="p-d-flex p-ai-center">
          <i className="pi pi-shield"></i>
          <h2>Process Master</h2>
        </div>
        <div className="p-d-flex p-ai-center wb-filter-btn">
          <div className="searchbar-area">
            <InputText
              type="search"
              onInput={(e) => setSearch(e.target.value)}
              placeholder="Global Search"
              size="30"
              style={{ marginRight: 10 }}
            />
            <i className="icon-search"></i>
          </div>
          {userPageAccess.includes("Add Process") ? (
            <div>
              <div
                className="e-add-btn"
                onClick={(e) => handleOpen(e, "AddMaster")}
              >
                <i className="icon-add"></i>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <hr />
      <div className="main-access-card-master">
        {loading === true ? (
          <div className="loading">
            <SimpleBackdrop />
          </div>
        ) : null}
        {userPageAccess.includes("View Process Master") ? (
          <DataTable
            className="main-table table-td"
            value={processList}
            editMode="row"
            dataKey="id"
            responsiveLayout="scroll"
            paginator={true}
            rows={rowsPerPage}
            totalRecords={processList.length}
            rowsPerPageOptions={rowsPerPageOptions}
            globalFilter={search}
            emptyMessage="No records found"
            responsive={false}
            resizableColumns={true}
            columnResizeMode="fit"
            scrollable={true}
            scrollWidth="auto"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          >
            <Column
              field="Index"
              header="Sr.No"
              body={actionBodyTemplateSrNo}
              style={{ width: "20%" }}
            ></Column>
            <Column
              field="ProcessName"
              header="Process Name"
              style={{ width: "20%" }}
            ></Column>

            <Column
              field="Action"
              header="Action"
              body={actionTemplate}
              headerStyle={{ width: "10%", minWidth: "8rem" }}
              bodyStyle={{ textAlign: "center" }}
            ></Column>
          </DataTable>
        ) : null}
        <Dialog
          className="stock-modal e-stock-modal"
          open={addProcessDialog}
          onClose={() => handleClose("AddMaster")}
          aria-labelledby="cha-form-dialog-title"
        >
          <DialogTitle
            id="cha-form-dialog-title"
            className="custom-dialog-header"
          >
            <div>
              {processId !== "" ? (readOnly ? "View " : "Edit ") : "Add "}
              Process
            </div>
            <div
              className="dialog-close"
              onClick={(e) => handleClose("AddMaster")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <DialogContent className="e-content-area">
            <GridContainer>
              <GridItem sm={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="ProcessName"
                  label="Process Name*"
                  type="text"
                  value={process.name}
                  onChange={(e) => handleChange(e, "processName")}
                  error={process.error}
                  helperText={process.errorText}
                  disabled={readOnly}
                  inputProps={{ maxLength: 40 }}
                  fullWidth
                />
              </GridItem>
              <div>
                <div className="dialog-heading p-w-100">
                  <h2>Input</h2>
                  <div>
                    <DataTable
                      className="main-table table-td"
                      style={{ width: "calc(100% + 50px)", height: "auto" }}
                      value={inputList}
                      editMode="row"
                      dataKey="id"
                      responsiveLayout="scroll"
                      emptyMessage="No records found"
                      columnResizeMode="fit"
                      scrollable={true}
                    >
                      <Column
                        field="Index"
                        header="Sr.No"
                        body={actionBodyTemplateSrNo}
                        style={{ width: "20%" }}
                      ></Column>
                      <Column
                        field="Item"
                        header="Items"
                        style={{ width: "20%" }}
                      ></Column>
                      <Column
                        field="Unit"
                        header="Unit"
                        style={{ width: "20%" }}
                        body={(rowData, props) =>
                          actionBodyData(rowData, props, "input", "Unit")
                        }
                      ></Column>
                      <Column
                        field="Quantity"
                        header="Qty"
                        style={{ width: "20%" }}
                        body={(rowData, props) =>
                          actionBodyData(rowData, props, "input", "Quantity")
                        }
                      ></Column>
                      {!readOnly && (
                        <Column
                          field="Action"
                          header="Action"
                          body={(rowData, props) =>
                            actionBodyTemplate(rowData, props, "input")
                          }
                          headerStyle={{ width: "10%", minWidth: "8rem" }}
                          bodyStyle={{ textAlign: "center" }}
                        ></Column>
                      )}
                    </DataTable>
                    <div className="p-d-flex p-ai-center">
                      <p className="e-add-row">add raw data</p>
                      {!readOnly && (
                        <div
                          className="filter-btn cursor-pointer"
                          onClick={(e) => handleOpen("Input", "AddIOProcess")}
                        >
                          <i className="icon-add"></i>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="dialog-heading p-w-100">
                  <h2>Output</h2>
                  <div>
                    <DataTable
                      className="main-table table-td"
                      style={{ width: "calc(100% + 50px)", height: "auto" }}
                      value={outputList}
                      editMode="row"
                      dataKey="id"
                      responsiveLayout="scroll"
                      emptyMessage="No records found"
                      responsive={false}
                      resizableColumns={true}
                      columnResizeMode="fit"
                      scrollable={true}
                      scrollWidth="auto"
                    >
                      <Column
                        field="Index"
                        header="Sr.No"
                        body={actionBodyTemplateSrNo}
                        style={{ width: "20%" }}
                      ></Column>
                      <Column
                        field="Item"
                        header="Items"
                        style={{ width: "20%" }}
                      ></Column>
                      <Column
                        field="Unit"
                        header="Unit"
                        style={{ width: "20%" }}
                        body={(rowData, props) =>
                          actionBodyData(rowData, props, "output", "Unit")
                        }
                      ></Column>
                      <Column
                        field="Quantity"
                        header="Qty"
                        style={{ width: "20%" }}
                        body={(rowData, props) =>
                          actionBodyData(rowData, props, "output", "Quantity")
                        }
                      ></Column>
                      {!readOnly && (
                        <Column
                          field="Action"
                          header="Action"
                          body={(rowData, props) =>
                            actionBodyTemplate(rowData, props, "output")
                          }
                          headerStyle={{ width: "10%", minWidth: "8rem" }}
                          bodyStyle={{ textAlign: "center" }}
                        ></Column>
                      )}
                    </DataTable>
                    <div className="p-d-flex p-ai-center p-mb-2">
                      <p className="e-add-row">add raw data</p>
                      {!readOnly && (
                        <div
                          className="filter-btn cursor-pointer"
                          onClick={(e) => handleOpen("Output", "AddIOProcess")}
                        >
                          <i className="icon-add"></i>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </GridContainer>
          </DialogContent>
          <DialogActions className="p-p-0 dialog-footer1">
            <Button
              className="primary-btn1"
              onClick={(e) => handleClose("AddMaster")}
            >
              Close
            </Button>
            {!readOnly && (
              <Button
                className="primary-btn"
                onClick={(e) => AddMaster(e)}
                type="submit"
              >
                Submit
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <Dialog
          open={addIOProcessDialog}
          onClose={() => handleClose("AddIOProcessMaster")}
          aria-labelledby="cha-form-dialog-title"
        >
          <DialogTitle
            id="cha-form-dialog-title"
            className="custom-dialog-header"
          >
            <div>Add {type} Process</div>
            <div
              className="dialog-close"
              onClick={(e) => handleClose("AddIOProcessMaster")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <DialogContent className="CHA-dialog-content-area">
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
                <div className="input-control select p-d-flex">
                  <FormControl fullWidth>
                    <Autocomplete
                      id="item"
                      name="item"
                      value={
                        item.value && itemList.length
                          ? itemList.filter((y) => y.value == item.value)[0]
                          : ""
                      }
                      options={itemList}
                      getOptionLabel={(option) =>
                        option.label ? option.label : option
                      }
                      onChange={(e, value) => handleChange(value, "item")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Item Name"
                          error={item.error}
                          helperText={item.errorText}
                          label="Item Name"
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <div className="input-control select p-d-flex">
                  <FormControl fullWidth>
                    <Autocomplete
                      id="unit"
                      name="unit"
                      value={
                        unit.value && unitList.length
                          ? unitList.filter((y) => y.value == unit.value)[0]
                          : ""
                      }
                      options={unitList}
                      getOptionLabel={(option) =>
                        option.label ? option.label : option
                      }
                      onChange={(e, value) => handleChange(value, "unit")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Unit Name"
                          error={unit.error}
                          helperText={unit.errorText}
                          label="Unit Name"
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </GridItem>
              <GridItem md={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="Qty"
                  label="Quantity*"
                  type="text"
                  value={qty.value}
                  onChange={(e) => handleChange(e, "qty")}
                  error={qty.error}
                  helperText={qty.errorText}
                  inputProps={{ maxLength: 10 }}
                  fullWidth
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions className="p-p-0 dialog-footer1">
            <Button
              className="primary-btn1"
              onClick={(e) => handleClose("AddIOProcessMaster")}
            >
              Close
            </Button>
            <Button
              className="primary-btn"
              onClick={(e) => addIOList(e)}
              type="submit"
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={deleteProcessDialog}
          onClose={() => handleClose("DeleteMasterType")}
          aria-labelledby="cha-form-dialog-title"
        >
          <DialogTitle
            id="cha-form-dialog-title"
            className="custom-dialog-header"
          >
            <div>Delete Process Master</div>
            <div
              className="dialog-close"
              onClick={(e) => handleClose("DeleteMasterType")}
            >
              <i className="icon-modal-close"></i>
            </div>
          </DialogTitle>
          <DialogContent className="CHA-dialog-content-area">
            <h6>Are you sure want to delete?</h6>
          </DialogContent>
          <DialogActions className="p-p-0 dialog-footer1">
            <Button
              className="primary-btn1"
              onClick={(e) => handleClose("DeleteMasterType")}
            >
              Close
            </Button>
            <Button
              className="primary-btn"
              onClick={(e) => deleteProcessMaster(e)}
              type="submit"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default ProcessMaster;
